<template>
  <div class="material_table_wrapper">
    <table class="material_table table">
      <thead>
      <tr>
        <th>
          <div class="table__header">
            №
          </div>
        </th>
        <th>
          <div class="table__header">
            Назва
          </div>
        </th>
        <th colspan="2">
          <div class="table__header">
            <span></span>
            <button class="button button__medium" v-b-modal.modal-name-add>
              Додати
            </button>
          </div>
        </th>
      </tr>
      </thead>
      <tbody v-if="detailsName">
      <tr v-for="(detail, index) in detailsName" :key="index">
        <td>{{ index + 1 }}</td>
        <td colspan="2">
          <div class="table__text">
            <input :id="'dt_'+index"
                   placeholder="Назва"
                   readonly
                   :value="detail.name"
                   type="text">
            <label :for="'dt_'+index"></label>
          </div>
        </td>
        <td>
          <div class="table__buttons">
            <b-button v-if="validaToSave(detail)"
                      @click="bvSave(detail)"
                      variant="outline-success"><i class="far fa-save"></i></b-button>

            <b-button variant="outline-danger" @click="remove(index)">
              <i class="far fa-trash-alt"></i>
            </b-button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>


    <b-modal
        id="modal-name-add"
        title="Вкажіть назву виробу"
        cancel-title="Закрити"
        ok-title="Додати"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
            label="Назва"
            label-for="name-input"
            :invalid-feedback="nameStateErrorText"
            :state="nameState"
        >
          <b-form-input
              id="name-input"
              v-model="detailName"
              :state="nameState"
              required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>

  </div>
</template>

<script>
import {productService} from "../../services/productService";

export default {
  name: "DetailsNameTable",
  props: ['detailsName'],
  data() {
    return {
      detailName: "",
      nameState: null,
      nameStateErrorText: "",
    }
  },
  methods: {
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity();
      const exists = this.detailsName.filter(detail => detail.name == this.detailName)

      if(!valid) {
        this.nameStateErrorText = 'Назва обов\'язкова'
      }
      if(exists.length > 0) {
        valid = false;
        this.nameStateErrorText = 'Такий виріб вже існує'
      }
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.detailName = '';
      this.nameStateErrorText = '';
      this.nameState = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      this.detailsName.push({id: null, name: this.detailName})

      this.$nextTick(() => {
        this.$bvModal.hide('modal-name-add')
      })
    },
    remove(i) {
      let nameItem = this.detailsName[i];
      app.details.forEach((el) => {
        if (el.name === nameItem.name) el.name = '';
      })
      this.$store.commit('data/REMOVE_NAME', i);
    },
    processSimilarName(currentDetail, key) {

      let oldList = Object.keys(this.detailsName).filter(item => item != key);
      let finded = oldList.filter(detail => this.detailsName[detail].name == currentDetail.name);

      if(finded.length > 0) {

          this.$store.commit('data/SET_DETAILS', this.$store.state.data.details.map((item) => {
            if(this.detailsName.filter(detail => detail.name === item.detail_name && item.detail_name.length > 0).length === 0) {
              item.detail_name = currentDetail.name;
            }
            return item;
          }));
          this.detailsName.splice(key, 1);
      }
    },
    bvSave(detail) {

      productService.existsProduct(detail.name, detail?.id).then(result => {
        if(result.data.exists) {
            this.$bvModal.msgBoxConfirm('Ви впевнені, що хочете перезаписати існуючий виріб?', {
              title: 'Підтвердіть',
              size: 'mm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'Так',
              cancelTitle: 'Ні',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            })
                .then((value) => {
                  if(value) {
                    detail.id = result.data.id
                    this.save(detail);
                  }
                })
        } else {
          this.save(detail);
        }
      })

    },
    save(detail) {
      if (this.loading) return;
      let details = this.$store.state.data.details.filter((item) => item.name === detail.name);
      this.loading = true;
      let data = {
        "customer": this.$store.state.auth.user.client.ref,
        "name": detail.name,
        "customer_material": this.$store.state.data.order?.customer_material || false,
        "customer_edge": this.$store.state.data.order?.customer_edge || false,
        "glue": this.$store.state.data.order?.glue || false,
        "edge_size": this.$store.state.data.order?.edge_size || false,
        "materials_list": [...new Set(details.map(item => item.material))],
        "edge_list": [...details.map(item => item.edge_t),
          ...details.map(item => item.edge_l),
          ...details.map(item => item.edge_r),
          ...details.map(item => item.edge_b),
          ...details.map(item => item.edge_template)],
        "screed_list": [...new Set(details.map(item => item.screed))],
        "items": details,
      };
      this.$toast.success("Вже робимо");
      if(detail.id !== null && detail.id !== undefined) {
        productService.updateProduct(detail.id, data).then(response => {
          detail.id = response.product.id;
          this.$toast.success("Успішно оновлено");
        }).then(r => {
          this.loading = false;
        }).catch(e => {
          this.loading = false;
          this.$toast.error("Щось пішло не так =(");
        })
      } else {
        productService.createProduct(data).then(response => {
          detail.id = response.product.id;
          this.$toast.success("Успішно збережено");
        }).then(r => {
          this.loading = false;
        }).catch(e => {
          this.loading = false;
          this.$toast.error("Щось пішло не так =(");
        });
      }

    },
    validaToSave(detail) {
      let details = this.$store.state.data.details.filter((item) => item.name === detail.name);
      return details.length > 0 && detail.name.length > 0;
    }
  },
  mounted() {
    this.$root.$on('update', i => {
      this.$forceUpdate();
    });
  }
}
</script>

<style scoped>
.table__buttons {
  gap: 10px;
  justify-content: end;
}
</style>