<template>
  <div>
    <div class="details">
      <div class="details__info">
        <div class="accordion" id="accordionExample">
          <div class="accordion_row">
            <div class="accordion_header" id="headingFour" data-toggle="collapse"
                 data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              <h3>
                Параметри замовлення
              </h3>
            </div>
            <div id="collapseFour" class="collapse accordion_content order-content" aria-labelledby="headingFour"
                 data-parent="#accordionExample">

              <div class="order-content__wrapper">
                <div class="order-content__inputs">
                  <div class="item">
                    <input type="checkbox" class="checkbox-input" id="customer_material"
                           v-model="customer_material" value="true">
                    <label for="customer_material">Матеріал Клієнта</label>
                  </div>

                  <div class="item">
                    <input type="checkbox" class="checkbox-input" id="customer_edge"
                           v-model="customer_edge"
                           value="true">
                    <label for="customer_edge">Кромка Клієнта</label>
                  </div>
                  <div class="item">
                    <input type="checkbox" class="checkbox-input" id="edge_size"
                           value="true"
                           v-model="edge_size">
                    <label for="edge_size">Розмір з Кромкою</label>
                  </div>

                  <div class="item">
                    <input type="checkbox" class="checkbox-input" id="glue" v-model="glue"
                           value="true">
                    <label for="glue">Оклеювання клеєм PUR</label>
                  </div>
                </div>
              </div>
            </div>

          </div>


          <div class="accordion_row">
            <div class="accordion_header" id="headingName" data-toggle="collapse"
                 data-target="#collapseName" aria-expanded="false" aria-controls="collapseName">
              <h3>Назва виробу</h3>
            </div>

            <div id="collapseName" class="collapse accordion_content" aria-labelledby="headingName"
                 data-parent="#accordionExample">
              <b-form-input v-model="productName" placeholder="Назва"></b-form-input>
            </div>
          </div>

          <div class="accordion_row">
            <div class="accordion_header" id="headingOne" data-toggle="collapse"
                 data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <h3>Матеріали</h3>
            </div>

            <div id="collapseOne" class="collapse accordion_content show" aria-labelledby="headingOne"
                 data-parent="#accordionExample">
              <div>
                <materials :toSearch="toSearch"
                           :materialsDefault="materialsDefault"
                           @interface="toSearch = $event"
                           :materials="materials"
                />
              </div>
            </div>
          </div>

          <div class="accordion_row">
            <div class="accordion_header" id="headingTwo" data-toggle="collapse"
                 data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              <h3>Кромки</h3>
            </div>
            <div id="collapseTwo" class="collapse accordion_content" aria-labelledby="headingTwo"
                 data-parent="#accordionExample">
              <div>
                <edges
                    :edgeDefault="edgeDefault"
                    @interface="toSearch = $event"
                    :edges="edges"
                    :toSearch="toSearch"/>
              </div>
            </div>
          </div>
          <div class="accordion_row">
            <div class="accordion_header" id="headingThree" data-toggle="collapse"
                 data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              <h3>
                Стяжки
              </h3>
            </div>
            <div id="collapseThree" class="collapse accordion_content" aria-labelledby="headingThree"
                 data-parent="#accordionExample">
              <div>
                <screeds :screeds="screeds"/>
              </div>
            </div>
          </div>
          <div class="accordion_row">
            <div class="order-confirm">

              <div class="order-confirm__buttons">
                <button class="button button__grey" :disabled="(this.details.length < 1) || loading"
                        @click="validateOrder">
                  Перевірити деталювання
                </button>
                <button @click="save"
                        :disabled="(this.details.length < 1) || loading"
                        class="button">
                  Зберегти
                </button>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div class="visual-container">
        <visual
            :detail="selectedDetail"
            :edges="edges"
            :materials="materials"
            v-if="details.length"/>
      </div>
    </div>

    <div class="form-table">
      <h4>Деталі
      </h4>
      <form id="detailTableForm" class="table-wrapper" data-simplebar>
        <table id="main_table" class="main_table table table-bordered">
          <thead>
          <tr>
            <th rowspan="2" style="min-width: 22px !important;">№</th>
            <th rowspan="2" style="min-width:80px !important;">Опції</th>
            <th colspan="2" style="min-width:62px !important;">Довжина</th>
            <th colspan="2" style="min-width:62px !important;">Ширина</th>
            <th rowspan="2" style="min-width:40px !important;">К-ть</th>
            <th v-b-tooltip.hover.right title="Обертати" rowspan="2" style="min-width:30px !important;">
                            <span>
                                <i class="fa fa-sync"></i>
                                <br>
                                <i class="fa fa-xs fa-question-circle"></i>
                            </span>
              <input type="checkbox" id="rotate_all" @change="rotateAll" class="checkbox-input">
              <label for="rotate_all"></label>
            </th>
            <th colspan="4" style="min-width:720px !important;max-width:720px !important;">Кромка</th>
            <th rowspan="2" style="min-width:242px !important;">Матеріал</th>
            <th colspan="3" style="min-width:91px !important;">Стяжка</th>

            <th rowspan="2" style="min-width:82px !important;">
                            <span>
                                Порізка за <br> текстурою
                            </span>
            </th>
            <th rowspan="2" style="min-width:120px !important;">Шаблон</th>
            <th rowspan="2" style="min-width:110px !important;">Виріб</th>
            <th rowspan="2" style="min-width:110px !important;">Деталь</th>
          </tr>
          <tr>
            <td style="min-width:70px !important;">мм</td>
            <td v-b-tooltip.hover.right title="Кількість сторін, що обклеюються, за довжиною"
                style="min-width:37px !important;">К
              <i class="fa fa-xs fa-question-circle"></i>
            </td>

            <td style="min-width:70px !important;">мм</td>
            <td v-b-tooltip.hover.right title="Кількість сторін, що обклеюються, за шириною"
                style="min-width:37px !important;">К
              <i class="fa fa-xs fa-question-circle"></i>
            </td>

            <td style="min-width: 180px !important;max-width: 180px !important;">Верх</td>
            <td style="min-width: 180px !important;max-width: 180px !important;">Низ</td>
            <td style="min-width: 180px !important;max-width: 180px !important;">Ліворуч</td>
            <td style="min-width: 180px !important;max-width: 180px !important;">Праворуч</td>

            <td v-b-tooltip.hover.right
                title="0 - Відсутня, 1-Звичайна, >1-Економ (Обов'язковий вибір Базової деталі 'БД')"
                style="min-width: 62px !important;"> <span>
                                Тип <i class="fa fa-xs fa-question-circle"></i>
                            </span>
            </td>
            <td v-b-tooltip.hover.right title="Базова деталь для Економ стяжки" colspan="2"
                style="min-width: 41px !important;">
              БД
              <i class="fa fa-xs fa-question-circle"></i>
            </td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(detail, index) in details" :data-key="index" :key="index"
              :class="index === activeIndex ? 'active' : ''"
              @keypress="visual(index)" @keyup="visual(index)" @mousedown="visual(index)">
            <td style="min-width: 22px !important;">{{ index + 1 }}</td>
            <td style="min-width: 80px !important;">
              <div class="btn-group">

                <b-button type="button" size="sm" v-b-tooltip.hover.right="'Копіювати'"
                          @click="copyDetail(index)"
                          variant="outline-info"><i class="far fa-copy"></i></b-button>

                <b-button type="button" size="sm" variant="outline-danger"
                          v-b-tooltip.hover.right="'Видалити'"
                          @click="removeDetail(index)"
                >
                  <i class="far fa-trash-alt"></i>
                </b-button>

              </div>
            </td>

            <td style="min-width: 70px !important;" :class="{'red tooltiper': detailValidation(detail.No, 'L_')}"
                v-b-tooltip.hover.right="detailValidationText(detail.No, 'L_', detail)">
              <input type="text" required step="0.1" min="20" max="9999.9" lang="en-150"
                     @keyup.enter="focusNextInput"
                     @keyup="onlyNumeric"
                     @change="fixedLength(detail,'length')"
                     v-model.number="detail.length"/>
            </td>
            <td style="min-width: 37px !important;">
              <input type="text" @keyup="onlyNumeric" required step="1" min="0" max="2"
                     @keyup.enter="focusNextInput"
                     v-model.number="detail.k1"
                     @input="addEdge($event.target.value, index, 1)"
                     class="sm-one"/>
            </td>
            <td style="min-width: 70px !important;" :class="{'red tooltiper': detailValidation(detail.No, 'W_')}"
                v-b-tooltip.hover.right="detailValidationText(detail.No, 'W_', detail)">
              <input type="text" step="0.1" min="20" required max="9999.9" lang="en-150"
                     @keyup.enter="focusNextInput"
                     @keyup="onlyNumeric"
                     @change="fixedLength(detail,'length')"
                     v-model.number="detail.width"/>
            </td>
            <td style="min-width: 37px !important;">
              <input type="text" @keyup="onlyNumeric" required step="1" min="0" max="2"
                     @keyup.enter="focusNextInput"
                     v-model.number="detail.k2"
                     @input="addEdge($event.target.value, index, 2)"
                     class="sm-one"/>
            </td>
            <td style="min-width: 40px !important;" :class="{'red tooltiper': detailValidation(detail.No, 'Qt_')}"
                v-b-tooltip.hover.right="detailValidationText(detail.No, 'Qt_', detail)">
              <input type="text"
                     @keyup.enter="focusNextInput"
                     @keyup="onlyNumeric" required step="1" min="1" lang="en-150"
                     v-model.number="detail.count" class="one-qt"/>
            </td>
            <td style="min-width: 30px !important;"
                :class="{'red tooltiper': detailValidation(detail.No, 'Rotate_')}"
                v-b-tooltip.hover.right="detailValidationText(detail.No, 'Rotate_',detail)">
              <input type="checkbox" :id="'Rotate_'+index" class="checkbox-input"
                     v-model.number="detail.rotate"
                     @keyup.enter="focusNextInput"
                     @change="checkboxChange($event.target.checked, 'rotate')">
              <label :for="'Rotate_'+index"></label>
            </td>

            <td style="min-width: 180px !important;max-width: 180px !important;"
                :class="{'red tooltiper': detailValidation(detail.No, 'EdgeT_')}"
                v-b-tooltip.hover.right="detailValidationText(detail.No, 'EdgeT_', detail)">
              <select class="custom-select three"
                      :style="{color:edges.find(el => el.ref === detail.edge_t)?.color || 'black'}"
                      @keyup.enter="focusNextInput"
                      @change="procesK(detail)"
                      v-model="detail.edge_t"
                      @keydown="selectByKey($event, edges, 'edge_t')">
                <option :value="null">Ні</option>
                <option v-for="(edge, i) in edges" :value="edge.ref" :key="i">
                  {{ (i + 1) + ' ' }} {{ edge.full_name }}
                </option>
              </select>
            </td>
            <td style="min-width: 180px !important;max-width: 180px !important;"
                :class="{'red tooltiper': detailValidation(detail.No, 'EdgeB_')}"
                v-b-tooltip.hover.right="detailValidationText(detail.No, 'EdgeB_', detail)">
              <select class="custom-select three"
                      :style="{color:edges.find(el => el.ref === detail.edge_b)?.color || 'black'}"
                      @keyup.enter="focusNextInput"
                      @change="procesK(detail)"
                      v-model="detail.edge_b"
                      @keydown="selectByKey($event, edges, 'edge_b')">
                <option :value="null">Ні</option>
                <option v-for="(edge, i) in edges" :value="edge.ref" :key="i">
                  {{ (i + 1) + ' ' }} {{ edge.full_name }}
                </option>
              </select>
            </td>
            <td style="min-width: 180px !important;max-width: 180px !important;"
                :class="{'red tooltiper': detailValidation(detail.No, 'EdgeL_')}"
                v-b-tooltip.hover.right="detailValidationText(detail.No, 'EdgeL_', detail)">
              <select class="custom-select three"
                      @keyup.enter="focusNextInput"
                      @change="procesK(detail)"
                      :style="{color:edges.find(el => el.ref === detail.edge_l)?.color || 'black'}"
                      v-model="detail.edge_l"
                      @keydown="selectByKey($event, edges, 'edge_l')">
                <option :value="null">Ні</option>
                <option v-for="(edge, i) in edges" :value="edge.ref" :key="i">
                  {{ (i + 1) + ' ' }} {{ edge.full_name }}
                </option>
              </select>
            </td>
            <td style="min-width: 180px !important;max-width: 180px !important;"
                :class="{'red tooltiper': detailValidation(detail.No, 'EdgeR_')}"
                v-b-tooltip.hover.right="detailValidationText(detail.No, 'EdgeR_', detail)">
              <select class="custom-select three"
                      @keyup.enter="focusNextInput"
                      @change="procesK(detail)"
                      :style="{color:edges.find(el => el.ref === detail.edge_r)?.color || 'black'}"
                      v-model="detail.edge_r"
                      @keydown="selectByKey($event, edges, 'edge_r')">
                <option :value="null">Ні</option>
                <option v-for="(edge, i) in edges" :value="edge.ref" :key="i">
                  {{ (i + 1) + ' ' }} {{ edge.full_name }}
                </option>
              </select>
            </td>

            <td style="min-width: 242px !important;max-width: 242px !important;">
              <select class="custom-select three"
                      v-model="detail.material"
                      required
                      v-b-tooltip.hover.top="materials.find(edge => edge.ref === detail.material)?.full_name ?? ''"
                      @keyup.enter="focusNextInput"
                      @keydown="selectByKey($event, materials, 'material')">
                <option value="" disabled>Виберіть*</option>
                <option v-for="(material, i) in materials" :value="material.ref" :key="i">
                  {{ (i + 1) + ' ' }} {{ material.full_name }}
                </option>
              </select>
            </td>
            <td style="min-width: 62px !important;"
                :class="{'red tooltiper': detailValidation(detail.No, 'Screed_')}"
                v-b-tooltip.hover.right="detailValidationText(detail.No, 'Screed_',detail)">
              <select class="custom-select one"
                      v-model.number="detail.screed"
                      @keyup.enter="focusNextInput"
                      @keydown="selectByKey($event, screeds, 'screed')">
                <option value="0">0</option>
                <option value="1">1</option>
                <option v-for="(screed, i) in screeds" :value="screed" :key="i">{{ screed }}
                </option>
              </select>
            </td>

            <td style="min-width: 41px !important;"
                :class="{'red tooltiper': detailValidation(detail.No, 'ScreedBP_')}"
                v-b-tooltip.hover.right="detailValidationText(detail.No, 'ScreedBP_',detail)">
              <input type="checkbox" :id="'ScreedBP_'+index" class="checkbox-input"
                     v-model.number="detail.screed_bp"
                     @keyup.enter="focusNextInput"
                     @change="checkboxChange($event.target.checked, 'screed_bp')">
              <label :for="'ScreedBP_'+index"></label>
            </td>

            <td style="min-width: 82px !important;"
                :class="{'red tooltiper': detailValidation(detail.No, 'CutToTxtrs_')}"
                v-b-tooltip.hover.right="detailValidationText(detail.No, 'CutToTxtrs_',detail)">
              <input type="checkbox" :id="'CutToTxtrs_'+index"
                     @keyup.enter="focusNextInput"
                     class="checkbox-input"
                     v-model.number="detail.cut_to_txtrs"
                     @change="checkboxChange($event.target.checked, 'cut_to_txtrs')">
              <label :for="'CutToTxtrs_'+index"></label>
            </td>
            <td style="min-width: 120px !important;"
                :class="{'red tooltiper': detailValidation(detail.No, 'Template_')}"
                v-b-tooltip.hover.right="detailValidationText(detail.No, 'Template_',detail)">
              <b-form-select id="baseTemplate" v-model.number="detail.template"
                             @keyup.enter="focusNextInput"
                             @change="pClear(index)">
                <b-form-select-option value="1">Відсутній</b-form-select-option>
                <b-form-select-option value="2">Косі різи</b-form-select-option>
                <b-form-select-option value="3">Фрезерування по дузі</b-form-select-option>
                <b-form-select-option value="4">Вибірка чверті</b-form-select-option>
                <b-form-select-option value="5">Паз під ДВП</b-form-select-option>
                <b-form-select-option value="6">Нестандартне фрезерування</b-form-select-option>
                <b-form-select-option value="7">Різ під нахилом пили</b-form-select-option>
                <b-form-select-option value="8">Фрезерування під петлі</b-form-select-option>

              </b-form-select>
            </td>
            <td style="min-width: 110px !important;">
              <input class="one" type="text" readonly

                     v-model="productName"/>
            </td>
            <td style="min-width: 110px !important;">
            <span>
              <input class="one" placeholder="Деталь" type="text"
                     v-model="detail.detail_name"/>
            </span>
            </td>

          </tr>
          </tbody>
        </table>
        <div class="button-list">
          <button class="button"
                  type="button"
                  v-hotkey="keymap"
                  :disabled="materials.length == 0" id="addNewDetail"
                  @click="addNewDetail"> Додати (Alt +)
          </button>
          <button class="button"
                  type="button"
                  :disabled="materials.length == 0"
                  @click="exportData">
            Експорт даних
          </button>
          <button class="button"
                  type="button"
                  @click="fileUploadEvent">
            Імпорт даних
          </button>


        </div>
        <input style="display: none" ref="fileImport" type="file" @change="handleFileUpload" accept=".xls, .xlsx"/>
      </form>

    </div>

    <details v-if="resp">
      <summary>Запит до 1С</summary>
      <pre style="border: 1px solid black">{{ resp.request_data }}</pre>
    </details>
    <details v-if="resp">
      <summary>Відповідь 1С</summary>
      <pre style="border: 1px solid black">{{ resp }}</pre>
    </details>

    <search
        :materials="materials"
        :edges="edges"
        v-bind:what="toSearch"/>
  </div>
</template>

<script>
import Edges from './EdgeTable.vue'
import Materials from './MaterialsTable.vue'
import Details from './DetailsNameTable.vue'
import Screeds from './Screeds.vue'
import Visual from './Visual.vue'
import Search from "./search.vue";
import axios from "axios";
import appconfig from "../../app/config";
import * as XLSX from "xlsx";
import {saveAs} from "file-saver";
import $ from "jquery";

export default {
  components: {
    Edges, Materials, Details, Screeds, Visual, Search
  },
  props: {
    modalShow: {
      type: Boolean,
    },
    materials: {
      type: Array,
      default() {
        return []
      }
    },
    edges: {
      type: Array,
      default() {
        return []
      }
    },
    screeds: {
      type: Array,
      default() {
        return []
      }
    },
    'title': {
      'type': String,
    },
    product: {
      'type': Object,
    },

  },
  watch: {
    product: {
      handler(value, old) {
        this.productName = value.name;
        this.customer_material = value.customer_material;
        this.customer_edge = value.customer_edge;
        this.edge_size = value.edge_size;
        this.glue = value.glue;
        this.details = value.details;

        this.edgeDefault = this.edges.length > 0 ? this.edges[0] : {};
        this.materialsDefault = this.materials.length > 0 ? this.materials[0] : {};
        this.visual(0);
        this.details.forEach(detail => this.procesK(detail));
      },
    }
  },
  data() {
    return {
      loading: false,
      activeIndex: 0,
      resp: null,
      rotateAllValue: 0,
      productName: this.product?.name || '',
      customer_material: this.product?.customer_material || false,
      edge_size: this.product?.edge_size || false,
      customer_edge: this.product?.customer_edge || false,
      glue: this.product?.glue || false,
      toSearch: '',
      selectedDetail: {},
      edgeDefault: this.edges.length > 0 ? this.edges[0] : {},
      materialsDefault: this.materials.length > 0 ? this.materials[0] : {},
      detailsName: [],
      details: this.product?.details || [],
      requiredHeaders: [
        "Довжина",
        "Ширина",
        "Кількість",
        "Обертати",
        "Верх",
        "Низ",
        "Ліворуч",
        "Праворуч",
        "Матеріал",
        "Стяжка",
        "Стяжка БД",
        "Порізка за текстурою",
        "Виріб",
        "Деталь",
      ],
      json_fields: [[
        'Довжина',
        'Ширина',
        'Кількість',
        'Обертати',
        'Верх',
        'Низ',
        'Ліворуч',
        'Праворуч',
        'Матеріал',
        'Стяжка',
        'Стяжка БД',
        'Порізка за текстурою',
        'Виріб',
        'Деталь',
      ]],
      colors: [
        '#1abc9c', '#c700fa', '#2746ac', '#9b59b6', '#34495e', '#e74c3c',
        '#e6223f', '#f39c12', '#7f8c8d', '#f368e0', '#341f97', '#6D214F',
        '#BDC581', '#fdaf00', '#795548', '#c0392b', '#0984e3', '#55efc4',
        '#fdcb6e', '#e84393', '#2d3436',
      ]
    };
  },
  computed: {
    keymap() {
      return {
        'alt++': this.addDetailHotKey,
        'alt+=': this.addDetailHotKey,
      }
    },
  },
  methods: {
    procesK(detail) {
      detail.k1 = (detail.edge_b !== null) + (detail.edge_t !== null);
      detail.k2 = (detail.edge_r !== null) + (detail.edge_l !== null);
    },
    fixedLength(detail, key) {
      detail[key] = detail[key].replace(/[^0-9\.\,]+/g, '') == '' ? '' : parseFloat(detail[key]).toFixed(1)
    },
    fileUploadEvent() {
      this.$refs.fileImport.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();

      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, {type: "binary"});

        // Беремо перший лист
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Конвертуємо лист у формат JSON
        const jsonData = XLSX.utils.sheet_to_json(sheet, {header: 1});

        if (jsonData.length) {
          const fileHeaders = jsonData[0]; // Перший рядок як заголовки

          // Перевірка заголовків
          const isValid = this.requiredHeaders.every((header) =>
              fileHeaders.includes(header)
          );

          if (!isValid) {
            this.$toast.error("Завантажений файл має неправильні колонки");
            return;
          }

          const data = jsonData.slice(1);
          this.details = data.map((item) => {
            return {
              length: item[0],
              width: item[1],
              count: item[2],
              rotate: item[3],
              edge_t: this.edges[item[4] - 1]?.ref ?? null,
              edge_b: this.edges[item[5] - 1]?.ref ?? null,
              edge_l: this.edges[item[6] - 1]?.ref ?? null,
              edge_r: this.edges[item[7] - 1]?.ref ?? null,
              material: this.materials[item[8] - 1]?.ref ?? null,
              screed: this.screeds.find(screed => screed == item[9]) != undefined || item[9] == 1 ? item[9] : 0,
              screed_bp: item[10],
              cut_to_txtrs: item[11],
              name: this.detailsName[item[12] - 1]?.name ?? '',
              detail_name: item[13],
              template: 1,
              "No": this.details.length > 0 ? this.details[this.details.length - 1]['No'] + 1 : 1,
              "k1": 0,
              "k2": 0,
              "note": '',
              "p1": 0,
              "p2": 0,
              "p3": 0,
              "p4": 0,
              "edge_template": null,
              "file": '',
            };
          })
          this.details.forEach(detail => this.procesK(detail))
          this.visual(0);
        } else {
          this.$toast.error("Файл порожній або має неправильний формат.");
        }
        this.$refs.fileImport.value = ''

      };

      reader.readAsBinaryString(file);
    },
    exportData() {
      let data = this.details.map((detail) => {
        let edgeT = this.edges.findIndex(item => item.ref === detail.edge_t),
            edgeB = this.edges.findIndex(item => item.ref === detail.edge_b),
            edgeL = this.edges.findIndex(item => item.ref === detail.edge_l),
            edgeR = this.edges.findIndex(item => item.ref === detail.edge_r),
            name = this.detailsName.findIndex(item => item.name === detail.name),
            material = this.materials.findIndex(item => item.ref === detail.material);


        return [
          detail.length,
          detail.width,
          detail.count,
          detail.rotate,
          edgeT === -1 ? '' : (edgeT + 1),
          edgeB === -1 ? '' : (edgeB + 1),
          edgeL === -1 ? '' : (edgeL + 1),
          edgeR === -1 ? '' : (edgeR + 1),
          material === -1 ? '' : (material + 1),
          detail.screed,
          detail.screed_bp,
          detail.cut_to_txtrs,
          name == -1 ? '' : (name + 1),
          detail.detail_name,
        ]
      })
      // Перетворюємо дані в аркуш
      const worksheet = XLSX.utils.aoa_to_sheet([...this.json_fields, ...data]);

      // Створюємо книгу
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Лист1");

      // Генеруємо файл Excel
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array"
      });

      // Зберігаємо файл
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream"
      });
      saveAs(blob, "example.xlsx");
    },
    addDetailHotKey() {
      if (this.materials.length !== 0) {
        this.addNewDetail()
      }
    },
    detailValidation(i, p) {

      if (this.resp?.Parts) {
        let item = this.resp.Parts.find(part => part.No == i);
        let response = '';

        if (item) {
          if (item[p] !== undefined) {
            response = `Вірно: ${item[p]}`;
          }

          if (p === 'Template_') {
            const hasExtendedDetails = ['P1_', 'P2_', 'P3_', 'P4_', 'EdgeA1_'].some(key => item[key] !== undefined);
            if (hasExtendedDetails) {
              response = 'Помилка у розширеному деталюванні';
            }
          }

          if (item[p] === 0) {
            if (p.includes('Edge')) {
              response = 'Вірно: Ні';
            } else if (p.includes('rotate') || p.includes('screed_bp')) {
              response = 'Вірно: Викл(0)';
            }
          }
        }

        return response;
      }

      return '';
    },
    detailValidationText(i, p, detail = null) {

      if (this.resp?.Parts) {
        let item = this.resp.Parts.find(part => part.No == i);
        let response = '';

        if (item) {
          if (item[p] !== undefined) {
            response = `Вірно: ${item[p]}`;
          }

          if (p === 'Template_') {
            const hasExtendedDetails = ['P1_', 'P2_', 'P3_', 'P4_', 'EdgeA1_'].some(key => item[key] !== undefined);
            if (hasExtendedDetails) {
              response = 'Помилка у розширеному деталюванні';
            }
          }

          if (item[p] === 0) {
            if (p.includes('Edge')) {
              response = 'Вірно: Ні';
            } else if (p.includes('rotate') || p.includes('screed_bp')) {
              response = 'Вірно: Викл(0)';
            }
          }
        }

        return response;
      }

      const edgeMap = {
        'EdgeT_': detail.edge_t,
        'EdgeB_': detail.edge_b,
        'EdgeL_': detail.edge_l,
        'EdgeR_': detail.edge_r
      };

      if (p in edgeMap) {
        return this.edges.find(edge => edge.ref === edgeMap[p])?.full_name ?? '';
      }

      return '';
    },
    validateOrder() {
      if (document.getElementById('detailTableForm').reportValidity() === false) {
        return false;
      }
      this.loading = true;
      let order = this.getOrderObject();
      order.check_parts = true;
      this.$toast.info("Запит у систему обліку")
      axios.post(
          appconfig.Point + 'api/constructor/v1/order/validate',
          this.getOrderObject()
      ).then(response => {
        response = response.data;
        this.resp = response;
        this.$store.commit('data/SET_RESPONSE_DEBUG', response)
        this.$store.commit('data/SET_RESPONSE', response);
        if (response.Error == true) {
          if (typeof response.ErrorMsg != 'undefined') {
            this.$toast.error(response.ErrorMsg)
          } else {
            this.$toast.error("У деталіровці знайдені помилки! Зверніть увагу на червоні виділення полів, при наведенні на які буде показано правильний варіант.")
          }
        } else {
          this.$toast.success('Помилок не знайдено!')
        }
      }).then(r => {
        this.loading = false;
      })
    },
    focusNextInput(e) {
      let nextInput = e.target.parentElement.nextElementSibling || e.target.parentElement.parentElement.nextElementSibling;
      nextInput = nextInput.querySelector('input') || nextInput.querySelector('select');
      if ($(nextInput).hasClass('checkbox-input') && this.materials.length !== 0) {
        this.addNewDetail()
        this.$nextTick();
        $(nextInput).parents('tr').next().find('[type="text"]').eq(0).focus();
        setTimeout(() => {
          $(nextInput).parents('tr').next().find('[type="text"]').eq(0).focus();
        })
      }
      if (nextInput) {
        nextInput.focus();
        if (typeof nextInput.select == 'function') {
          nextInput.select();
        }
      }
    },
    rotateAll() {
      this.rotateAllValue = this.rotateAllValue === 1 ? 0 : 1;
      this.details.forEach((item) => item.rotate = this.rotateAllValue)
    },
    pClear(i) {
      this.detail.p1 = 0;
      this.detail.p2 = 0;
      this.detail.p3 = 0;
      this.detail.p4 = 0;
      this.detail.edge_template = null;
      this.detail.note = '';
      this.detail.file = '';

      this.details[i].p1 = 0;
      this.details[i].p2 = 0;
      this.details[i].p3 = 0;
      this.details[i].p4 = 0;
      this.details[i].edge_template = null;
      this.details[i].note = '';
      this.details[i].file = '';

      let itemKey = Object.keys(this.resp.Parts).find(key => this.resp.Parts[key].No == i + 1);
      if (itemKey) {
        if (this.resp.Parts[itemKey]['P1_'] != undefined) {
          this.resp.Parts[itemKey]['P1_'] = undefined;
        }
        if (this.resp.Parts[itemKey]['P2_'] != undefined) {
          this.resp.Parts[itemKey]['P2_'] = undefined;
        }
        if (this.resp.Parts[itemKey]['P3_'] != undefined) {
          this.resp.Parts[itemKey]['P3_'] = undefined;
        }
        if (this.resp.Parts[itemKey]['P4_'] != undefined) {
          this.resp.Parts[itemKey]['P4_'] = undefined;
        }
        if (this.resp.Parts[itemKey]['EdgeA1_'] != undefined) {
          this.resp.Parts[itemKey]['EdgeA1_'] = undefined;
        }
      }
    },
    addAdditional(type, data) {
      if(['edges', 'materials'].indexOf(type) !== -1) {
        let elem = this[type].find(el => el.ref === data.ref) || null;
        if (elem) return;
      }

      this[type].push(data);
      if (type === 'edges' && Object.keys(this.edgeDefault).length === 0) {
        this.edgeDefault = data;
      }
      if (type === 'materials' && Object.keys(this.materialsDefault).length === 0) {
        this.materialsDefault = data;
      }
    },
    visual(i) {
      this.selectedDetail = this.details[i];
      this.activeIndex = i;

    },
    copyDetail() {
      let d = {...this.selectedDetail};
      this.details.push(d);
    },
    processDetailsNo() {
      this.details = this.details.map((detail, index) => {
        detail.No = index + 1;
        return detail;
      })
    },
    removeDetail(i) {
      this.details.splice(i, 1);

      if (this.details.length === 0) {
        this.selectedDetail = {};
      } else {
        this.selectedDetail = this.details[this.details.length - 1];
      }
    },
    getOrderObject() {
      this.resp = null;
      let production_params;
      this.processDetailsNo();
      production_params = this.$store.state.auth.user.client.production_params[0];
      return {
        "dept": "ТПМ",
        "section": production_params.section,
        "stock": this.$store.state.auth.user.defaultStock,
        "cy": "980",//980
        "manager": production_params.manager,
        "price_type": production_params.price_type,
        "draw_cut": false,
        "to_work": false,
        "check_parts": false,
        "customer": this.$store.state.auth.user.client.ref,
        "name": this.productName,
        "customer_material": this.customer_material,
        "customer_edge": this.customer_edge,
        "glue": this.glue,
        "edge_size": this.edge_size,
        "materials_list": this.materials.map(item => item.ref),
        "edge_list": this.edges.map(item => item.ref),
        "screed_list": this.screeds,
        "materials_list_color": this.materials.map(item => item.color),
        "edge_list_color": this.edges.map(item => item.color),
        "items": this.details.map(detail => {
          detail.name = this.productName;
          return detail
        }),
      };
    },
    save() {
      if (document.getElementById('detailTableForm').reportValidity() === false) {
        return false;
      }
      this.$emit('on-submit', this.getOrderObject());
    },
    addEdge(v, i, t) {

      let defEdge = this.edgeDefault;
      if (this.edges.length === 0 || defEdge === undefined) return;

      if (v < 0) v = 0;

      if (v > 0 && t == 1) {
        this.details[i].edge_t = defEdge.ref;
        if (v == 2) {
          this.details[i].edge_b = defEdge.ref;
        } else {
          this.details[i].edge_b = null;
        }
      } else if (v == 0 && t == 1) {
        this.details[i].edge_t = null;
        this.details[i].edge_b = null;
      }

      if (v > 0 && t == 2) {
        this.details[i].edge_l = defEdge.ref;
        if (v == 2) {
          this.details[i].edge_r = defEdge.ref;
        } else {
          this.details[i].edge_r = null;
        }
      } else if (v == 0 && t == 2) {
        this.details[i].edge_l = null;
        this.details[i].edge_r = null;
      }
    },
    addNewDetail() {
      let cleanDeteil = {
        "No": this.details.length > 0 ? this.details[this.details.length - 1]['No'] + 1 : 1,
        "length": 20,
        "width": 20,
        "count": 1,
        "rotate": 0,
        "k1": 0,
        "k2": 0,
        "edge_t": null,
        "edge_b": null,
        "edge_l": null,
        "edge_r": null,
        "material": this.materialsDefault ? this.materialsDefault.ref : "",
        "note": '',
        "template": 1,
        "p1": 0,
        "p2": 0,
        "p3": 0,
        "p4": 0,
        "edge_template": null,
        "screed": 0,
        "screed_bp": 0,
        "cut_to_txtrs": 0,
        "name": this.productName,
        "detail_name": '',
        "file": '',
      };
      this.details.push(cleanDeteil);
      this.selectedDetail = this.details[this.details.length - 1];
    },
    checkbox(e, v) {
      if (e.key == 0) {
        this.details[v] = 0;
      } else if (e.key == 1) {
        this.details[v] = 1;
      }
    },
    checkboxChange(val, v) {
      this.details[v] = val ? 1 : 0;
    },
    onFileChanged($event, v) {
      const target = $event.target;
      if (target && target.files) {
        this.details[v] = target.files[0];
      }
    },
    selectByKey(e, data, v) {
      let val = e.key;
      if (+(val - 1) != NaN && data[val - 1] != undefined && data[val - 1].ref != undefined) {
        this.details[v] = data[val - 1].ref;
      }
      if (val == 0 && v != 'template') {
        this.details[v] = null;
      }
    },
    onlyNumeric(e) {
      if (e.key != '.' && e.key != ',') {
        e.target.value = e.target.value.replace(/[^0-9\.\,]+/g, '')
      }

    }
  },
  created() {
    this.$root.$off('on-save-product');
    this.$root.$off('on-remove');
    this.$root.$off('setEdgeDefault');
    this.$root.$off('setMaterialDefault');


    this.$root.$on('on-save-product', (type, data) => {
      this.addAdditional(type, data);
    });
    this.$root.$on('on-remove', (type, index) => {
      if (type == 'screeds') {
        let currentScreed = this[type][index];
        this.details.map((detail) => {
          if (detail.screed == currentScreed) {
            detail.screed = 0;
          }
          return detail;
        });
      }
      this[type].splice(index, 1);
    });
    this.$root.$on('setEdgeDefault', (value) => {
      this.edgeDefault = value;
    });
    this.$root.$on('setMaterialDefault', (value) => {
      this.materialsDefault = value;
    });
  },
  name: "product-form"
}
</script>

<style scoped>
tr.active {
  background-color: #d2d2d2;
}

tr.active * {
  background-color: #d2d2d2;
}
</style>