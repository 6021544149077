<template>
  <div>
    <div class="screed_table_wrapper">
      <table class="screed_table table table-bordered" width="10%">
        <thead>
        <tr>
          <th colspan="2">
            <div class="table__header">
              №
              <button class="button button__medium" @click="addScreed">Додати</button>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(screed, index) in screeds" :key="index">
          <td width="90%">{{ screed }}</td>
          <td width="10%">
            <div class="table__buttons">
              <button class="button button__icon" @click="remove(index)">
                <img :src="require('@/assets/img/trash-can.png')">
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "screeds",
  props: [
    'screeds'
  ],

  methods: {
    addScreed() {
      this.$root.$emit('on-save-product', 'screeds',
        this.screeds.length > 0 ? this.screeds[this.screeds.length - 1] + 1 : 2
      );
    },
    remove(i) {
      this.$root.$emit('on-remove', 'screeds', i);
    }
  }
}
</script>
<style>
.hidend {
  display: none;
}
</style>