<template>
  <div class="modal fade" id="recommendModal" style="overflow: auto!important;" tabindex="-1" role="dialog"
       aria-labelledby="recommendModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div>
            <h5 class="modal-title" id="recommendModalLabel">Супутні кромки </h5>
          </div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>


        </div>
        <div class="modal-body">
          <div class="analogs__product pl-4 pr-4 mb-4">
            <h6 v-if="material">{{ material.full_name || '' }}</h6>
            <div class="checkbox-all">
              <input type="checkbox" id="check-all"
                     @change="reverseCheckboxes"
                     :checked="checked"
                     class="checkbox-input" value="true">
              <label for="check-all"></label>
            </div>
          </div>
          <div class="d-flex justify-content-center mb-3">
            <div v-if="loading" class="mt-4 mb-4">
              <div class="preloader" ref="preloader">
                <div class="item-1"></div>
                <div class="item-2"></div>
                <div class="item-3"></div>
                <div class="item-4"></div>
                <div class="item-5"></div>
              </div>
            </div>
          </div>

          <table class="table recommends" v-if="received.length">
            <tbody>
            <tr v-for="(item, index) in received" :key="index">
              <td class="pb-0">
                <div class="search__image-wrap">
                  <img height="70" :src="appconfig.Point+`/`+item.image"
                       alt="">
                  <figcaption v-if="item.on_order" class="on_order_label">Під замовлення</figcaption>
                </div>
              </td>
              <td>
                {{ item.full_name }}
                <p class="mt-4 mb-0" style="font-size: 13px">
                  <span class="text-muted">  Код: </span>{{ item.code }}
                  <span class="text-muted"> | </span>
                  <span class="item__sku">
                       <span class="item__sku--terms"
                             v-if="it(item).quantity == 0"> Термін постачання до: {{ item.term_order }} дн.</span>
                        <span class="item__sku--instock"
                              v-else> В наявності</span>
                    </span>

                </p>
              </td>

              <td>
                <div class="item__sku--price">
                  <div class="item__price-wrap" v-if="item.pr > 0">
                    <p class="item__price">
                      {{ item.pr.toFixed(2) }}
                      <span class="item__price-cur">{{ item.cy_name }}
                                </span>
                    </p>
                    <span class="item__ratio">
                                    {{ item.sale_unit_name }}
                                    </span>
                  </div>

                </div>
              </td>

              <td>
                <input v-if="item.pr > 0" type="checkbox" :data-ref="item.ref" :id="'_'+item.ref"
                       v-model="item.checked"
                       class="checkbox-input" value="true">
                <label :for="'_'+item.ref"></label>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button class="button" @click="takeItems()">Обрати</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import axios from "axios";

export default {
  name: "recommendRender",
  data() {
    return {
      appconfig: appconfig,
      edges: [],
      material: false,
      received: [],
      loading: false,
      checked: false,
    }
  },
  methods: {
    takeItems() {
      this.received.forEach((edge) => {
        if(!edge.checked) return;
        this.$root.$emit('on-save-product', 'edges', edge);
        $('#recommendModal').modal('hide');
      });

    },
    reverseCheckboxes() {
      this.checked = !this.checked;
      this.received.map(el => {
        return el.checked = this.checked && el.price > 0;
      });
    },
    receive() {
      let request = '';
      this.edges.forEach((el, i) => {
        request += 'i' + el.code;
      });
      this.loading = true;
      axios.post(appconfig.Point + 'api/constructor/v1/products', {
        by: 'code',
        search: request,
        take: 20,
        token: (this.$store.state.auth.user.token || null)
      })
          .then(response => {
            this.received = response.data;
            this.loading = false;
          })
          .catch(e => {
            console.log(e);
          })
    },
    it(item) {
      return this.received.find(el => el.ref === item.ref) || {};
    },
  },
  mounted() {
    this.$root.$off('edges_change');
    this.$root.$on('edges_change', data => {
      this.edges = data.edges;
      this.material = data.material;
      this.receive();
      this.checked = false;
      this.edges.map(el => {
        el.checked = false;
      });
    });
    $('#recommendModal').on('hidden.bs.modal', () => {
      this.$root.$emit('modal.change')
    });
  }
}
</script>

<style scoped>
.table td {
  overflow: hidden;
  vertical-align: middle;
}

p {
  letter-spacing: 0.05px;
  word-spacing: -0.93px;
}

.item__sku > span {
  position: relative;
  color: #19459d;
}

.item__sku--terms::before, .item__sku--terms::before {
  content: url('~@/assets/img/car_truck.svg');
  margin-right: 5px;
  top: 2px;
  position: relative;
}

.item__sku--instock::before, .item__sku--instock::before {
  content: '';
  background: url('~@/assets/img/check-circle.svg') no-repeat center;
  margin-right: 5px;
  width: 18px;
  height: 16px;
  display: inline-block;
  top: 3px;
  position: relative;
}

.analogs__product {
  display: flex;
  justify-content: space-between;
}

.checkbox-all {
  padding-right: 8px;
}

</style>