function getDefaultState() {
    return {
        detail: {},
        details: [],
        materials: [],
        edges: [],
        screeds: [],
        detailsNames: [],
        defaultMaterial: null,
        modal: '',
        defaultEdges: null,
        order: null,
        replace: null,
        response: null,
        responseDebug: null,
        colors: [
            '#1abc9c', '#c700fa', '#2746ac', '#f80a4e', '#34495e', '#e74c3c',
            '#0e7e28', '#f39c12', '#7f8c8d', '#f368e0', '#341f97', '#6D214F',
            '#BDC581', '#fdaf00', '#795548', '#c0392b', '#0984e3', '#55efc4',
            '#fdcb6e', '#e84393', '#2d3436',
        ]
    };
}

function procesK(detail) {
    detail.k1 = (detail.edge_b != null) + (detail.edge_t != null);
    detail.k2 = (detail.edge_r != null) + (detail.edge_l != null);
    return detail;
}

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        materials(state) {
            return state.materials
        },
        detail(state) {
            return state.detail
        },
        edges(state) {
            return state.edges
        },
        screeds(state) {
            return state.screeds
        },
        detailsNames(state) {
            return state.detailsNames
        },
        defaultMaterial(state) {
            let check = false;
            if(state.defaultMaterial) {
                check = state.materials.find((el) => el.ref === state.defaultMaterial.ref)
            }
            return check ? state.defaultMaterial : (state.materials[0] || null);
        },
        defaultEdge(state) {
            let check = false;
            if(state.defaultEdges) {
                check = state.edges.find((el) => el.ref === state.defaultEdges.ref)
            }
            return check ? state.defaultEdges : (state.edges[0] || null);
        }
    },
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
        },
        SET_DETAIL(state, value) {
            state.detail = value;
        },
        SET_MODAL(state, value) {
            state.modal = value;
        },
        SET_RESPONSE(state, value) {
            state.response = value;
        },
        SET_RESPONSE_DEBUG(state, value) {
            state.responseDebug = value;
        },
        SET_ORDER(state, value) {
            state.order = value;
        },
        SET_REPLACE(state, value) {
            state.replace = value;
        },
        SET_DETAILS(state, value) {
            state.details = value;
            if(state.details != null) {
                state.details = state.details.map(detail => procesK(detail))
            }
        },
        SET_DEFAULT_MATERIALS(state, value) {
            state.defaultMaterial = value;
        },
        SET_DEFAULT_EDGES(state, value) {
            state.defaultEdges = value;
        },
        SET_MATERIALS(state, value) {
            state.materials = value
        },
        ADD_MATERIALS(state, value) {
            state.materials.push(value)
        },
        REMOVE_MATERIAL(state, value) {
            state.materials.splice(value, 1)
        },
        REMOVE_NAME(state, value) {
            state.detailsNames.splice(value, 1)
        },
        SET_EDGES(state, value) {
            state.edges = value
            // state.edges = value.map((val, i) => {
            //     val['color'] = state.colors[i];
            //     return val
            // })
        },
        ADD_EDGES(state, value) {
            // value['color'] = state.colors[state.edges.length + 1]
            state.edges.push(value)
        },
        SET_SCREEDS(state, value) {
            state.screeds = value.length > 0 ? value.sort((a, b) => a.No - b.No).filter(i => i.No != 1) : value;
        },
        SET_NAMES(state, value) {
            state.detailsNames = value
        },
        ADD_SCREED(state, value) {
            state.screeds.push(value)
        },
        REMOVE_SCREED(state, value) {
            let currentScreed = state.screeds[value];

            state.details.map((detail) => {
                if(detail.screed == currentScreed.No) {
                    detail.screed = 0;
                }
                return detail;
            });

            state.screeds.splice(value, 1)
        },
    }
}