<template>
  <div class="help-main mt-5">
    <b-card no-body>
      <b-tabs pills fill card v-model="tabIndex">
        <b-tab v-show="videos.length > 0" title="Відео">
            <b-card
                class="overflow-hidden mb-2"
                no-body
                v-for="video in videos"
                tag="article"
            >
              <b-row no-gutters>
                <b-col md="6">
                  <b-card-img :src="video.poster" alt="Image" class="rounded-0"></b-card-img>
                </b-col>
                <b-col md="6">
                  <b-card-body :title="video.title">
                  <b-card-text>
                    {{video.description}}
                  </b-card-text>
                  <b-button @click="showVideo(video)" variant="primary">Дивитись відео</b-button>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
        </b-tab>
        <b-tab title="Шаблони">
          <b-row>
            <b-col cols="12">
              <b-card
                  no-body
                  class="mb-2"
              >
                <b-row no-gutters>
                  <b-col md="3">
                    <b-card-img width="250" :src="require('@/assets/img/1.jpg')" class="rounded-0"></b-card-img>
                  </b-col>
                  <b-col md="9">
                    <b-card-body title="Відсутній (№1)">
                      <b-card-text>
                        <ul class="list-unstyled">
                          <li>Розміри деталі, мм:
                            <ul>
                              <li>Мінімальні (без оклеювання кромкою) – 20х20</li>
                              <li>Мінімальні (з оклеюванням кромкою)* – 120х70</li>
                            </ul>
                          </li>
                          <li>
                            Примітки:
                            <ul>
                              <li>* можливе зменшення розмірів при підрізці з однієї або двох сторін</li>
                            </ul>
                          </li>
                        </ul>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col cols="12">
              <b-card
                  no-body
                  class="mb-2"
              >
                <b-row no-gutters>
                  <b-col md="3">
                    <b-card-img width="250" :src="require('@/assets/img/2.jpg')" class="rounded-0"></b-card-img>
                  </b-col>
                  <b-col md="9">
                    <b-card-body title="Косі різи (№2)">
                      <b-card-text>
                        <ul class="list-unstyled">
                          <li>Розміри деталі, мм:
                            <ul>
                              <li>Мінімальні (без оклеювання кромкою) – 20х20</li>
                              <li>Мінімальні (з оклеюванням кромкою)* – 120х70</li>
                            </ul>
                          </li>

                          <li>
                            Параметри:
                            <ul>
                              <li>Кут знизу ліворуч:
                                <ul>
                                  <li>відступ з права (Р1), мм</li>
                                  <li>відступ знизу (Р2), мм</li>
                                </ul>
                              </li>
                              <li>Кут знизу праворуч:
                                <ul>
                                  <li>відступ з ліва (Р3), мм</li>
                                  <li>відступ знизу (Р4), мм</li>
                                </ul>
                              </li>
                            </ul>
                          </li>

                          <li>Обмеження:
                            <ul>
                              <li>Р1, Р3 – не більше ширини деталі</li>
                              <li>(Р2+Р4) – не більше довжини деталі</li>
                            </ul>
                          </li>

                          <li>
                            Примітки:
                            <ul>
                              <li>* можливе зменшення розмірів при підрізці з однієї або двох сторін</li>
                            </ul>
                          </li>
                        </ul>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col cols="12">
              <b-card
                  no-body
                  class="mb-2"
              >
                <b-row no-gutters>
                  <b-col md="3">
                    <b-card-img width="250" :src="require('@/assets/img/3.jpg')" class="rounded-0"></b-card-img>
                  </b-col>
                  <b-col md="9">
                    <b-card-body title="Фрезерування по дузі (№3)">
                      <b-card-text>
                        <ul class="list-unstyled">
                          <li>Розміри деталі, мм:
                            <ul>
                              <li>Мінімальні (без оклеювання кромкою)*:
                                <ul>
                                  <li>1 радіус – 350х60</li>
                                  <li>2 радіуса – 400х60</li>
                                  <li>3 або 4 радіуса – 400х120</li>
                                </ul>
                              </li>
                              <li>Мінімальні (з оклеюванням кромкою)*:
                                <ul>
                                  <li>1 радіус – 350х70</li>
                                  <li>2 радіуса – 400х70</li>
                                  <li>3 або 4 радіуса – 400х120</li>
                                </ul>
                              </li>
                              <li>Максимальні – 2800х1550</li>
                            </ul>
                          </li>

                          <li>
                            Параметри:
                            <ul>
                              <li>Радіус кута, мм:
                                <ul>
                                  <li>вгорі ліворуч (Р1)</li>
                                  <li>знизу ліворуч (Р2)</li>
                                  <li>знизу праворуч (Р3)</li>
                                  <li>вгорі праворуч (Р4)</li>
                                </ul>
                              </li>
                              <li>Оклеювання кромкою фрезерування</li>
                            </ul>
                          </li>

                          <li>Обмеження:
                            <ul>
                              <li>(Р1+Р2), (Р3+Р4) – не більше ширини деталі</li>
                              <li>(Р1+Р4), (Р2+Р3) – не більше довжини деталі</li>
                            </ul>
                          </li>

                          <li>
                            Примітки:
                            <ul>
                              <li>* можливе зменшення розмірів при підрізці з однієї або двох сторін</li>
                            </ul>
                          </li>
                        </ul>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col cols="12">
              <b-card
                  no-body
                  class="mb-2"
              >
                <b-row no-gutters>
                  <b-col md="3">
                    <b-card-img width="250" :src="require('@/assets/img/4.jpg')" class="rounded-0"></b-card-img>
                  </b-col>
                  <b-col md="9">
                    <b-card-body title="Вибірка чверті (№4)">
                      <b-card-text>
                        <ul class="list-unstyled">
                          <li>Розміри деталі, мм:
                            <ul>
                              <li>Мінімальні (без оклеювання кромкою)* – 350х60
                              </li>
                              <li>Мінімальні (з оклеюванням кромкою)* – 350х70
                              </li>
                              <li>Максимальні – 2800х1550</li>
                            </ul>
                          </li>

                          <li>
                            Параметри:
                            <ul>
                              <li>Глибина паза (Р1), мм</li>
                              <li>Ширина паза (Р2), мм</li>
                              <li>Обробляємі сторони:
                                <ul>
                                  <li>за довжиною (Р3): без обробки, одна, дві</li>
                                  <li>за шириною (Р4): без обробки, одна, дві</li>
                                </ul>
                              </li>
                            </ul>
                          </li>

                          <li>Обмеження:
                            <ul>
                              <li>(Товщина матеріала-Р1) – не менше 8 мм</li>
                            </ul>
                          </li>

                          <li>
                            Примітки:
                            <ul>
                              <li>* можливе зменшення розмірів при підрізці з однієї або двох сторін</li>
                            </ul>
                          </li>
                        </ul>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col cols="12">
              <b-card
                  no-body
                  class="mb-2"
              >
                <b-row no-gutters>
                  <b-col md="3">
                    <b-card-img width="250" :src="require('@/assets/img/5.jpg')" class="rounded-0"></b-card-img>
                  </b-col>
                  <b-col md="9">
                    <b-card-body title="Паз під ДВП (№5)">
                      <b-card-text>
                        <ul class="list-unstyled">
                          <li>Розміри деталі, мм:
                            <ul>
                              <li>Мінімальні (без оклеювання кромкою) – 20х20
                              </li>
                              <li>Мінімальні (з оклеюванням кромкою)* – 120х70
                              </li>
                            </ul>
                          </li>

                          <li>
                            Параметри:
                            <ul>
                              <li>Глибина паза (Р1), мм</li>
                              <li>Ширина паза (Р2), мм</li>
                              <li>Відступ (Р3), мм</li>
                              <li>Розташування (Р4): за довжиною, за шириною</li>
                            </ul>
                          </li>

                          <li>Обмеження:
                            <ul>
                              <li>(Товщина матеріала-Р1) – не менше 8 мм</li>
                              <li>Р2 – 3 або 4 мм</li>
                              <li>Р3 – не менше 8 мм</li>
                            </ul>
                          </li>

                          <li>
                            Примітки:
                            <ul>
                              <li>* можливе зменшення розмірів при підрізці з однієї або двох сторін</li>
                            </ul>
                          </li>
                        </ul>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col cols="12">
              <b-card
                  no-body
                  class="mb-2"
              >
                <b-row no-gutters>
                  <b-col md="3">
                    <b-card-img width="250" :src="require('@/assets/img/6.jpg')" class="rounded-0"></b-card-img>
                  </b-col>
                  <b-col md="9">
                    <b-card-body title="Нестандартне фрезерування (№6)">
                      <b-card-text>
                        <ul class="list-unstyled">
                          <li>Розміри деталі, мм:
                            <ul>
                              <li>Мінімальні (без оклеювання кромкою)* – 350х60
                              </li>
                              <li>Мінімальні (з оклеюванням кромкою)* – 350х70
                              </li>
                              <li>Максимальні – 2800х1550</li>
                              <li>Максимальні для стільниць – 4100х1550</li>
                            </ul>
                          </li>

                          <li>
                            Параметри:
                            <ul>
                              <li>Обробляємі сторони:
                                <ul>
                                  <li>за довжиною (Р1): без обробки, одна, дві</li>
                                  <li>за шириною (Р2): без обробки, одна, дві</li>
                                </ul>
                              </li>
                              <li>Оклеювання кромкою фрезерування</li>
                              <li>Ескіз деталі – завантаження файлу (обов’язково)</li>
                            </ul>
                          </li>

                          <li>
                            Примітки:
                            <ul>
                              <li>* можливе зменшення розмірів при підрізці з однієї або двох сторін</li>
                            </ul>
                          </li>
                        </ul>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col cols="12">
              <b-card
                  no-body
                  class="mb-2"
              >
                <b-row no-gutters>
                  <b-col md="3">
                    <b-card-img width="250" :src="require('@/assets/img/7.jpg')" class="rounded-0"></b-card-img>
                  </b-col>
                  <b-col md="9">
                    <b-card-body title="Різ під нахилом пили (№7)">
                      <b-card-text>
                        <ul class="list-unstyled">
                          <li>Розміри деталі, мм:
                            <ul>
                              <li>Мінімальні (без оклеювання кромкою) – 120х70
                              </li>
                              <li>Мінімальні (з оклеюванням кромкою)* – 120х70
                              </li>
                            </ul>
                          </li>

                          <li>
                            Параметри:
                            <ul>
                              <li>Обробляємі сторони:
                                <ul>
                                  <li>за довжиною (Р1): без обробки, одна, дві</li>
                                  <li>за шириною (Р2): без обробки, одна, дві</li>
                                </ul>
                              </li>
                            </ul>
                          </li>

                          <li>
                            Примітки:
                            <ul>
                              <li>* можливе зменшення розмірів при підрізці з однієї або двох сторін</li>
                            </ul>
                          </li>
                        </ul>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col cols="12">
              <b-card
                  no-body
                  class="mb-2"
              >
                <b-row no-gutters>
                  <b-col md="3">
                    <b-card-img width="250" :src="require('@/assets/img/8.jpg')" class="rounded-0"></b-card-img>
                  </b-col>
                  <b-col md="9">
                    <b-card-body title="Фрезерування під петлі (№8)">
                      <b-card-text>
                        <ul class="list-unstyled">
                          <li>Розміри деталі, мм:
                            <ul>
                              <li>Мінімальні (без оклеювання кромкою) – 20х20
                              </li>
                              <li>Мінімальні (з оклеюванням кромкою)* – 120х70
                              </li>
                              <li>Максимальні – 2800х850</li>
                            </ul>
                          </li>

                          <li>
                            Параметри:
                            <ul>
                              <li>Кількість петель (Р1)</li>
                              <li>Розташування (Р2): за довжиною, за шириною</li>
                            </ul>
                          </li>

                          <li>
                            Обмеження:
                            <ul>
                              <li>Р1 – від 2 до 7</li>
                            </ul>
                          </li>

                          <li>
                            Примітки:
                            <ul>
                              <li>* можливе зменшення розмірів при підрізці з однієї або двох сторін</li>
                            </ul>
                          </li>
                        </ul>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

          </b-row>
        </b-tab>

      </b-tabs>
    </b-card>

    <b-modal id="modalVideo" size="xl" :title="currentVideo.title">
      <b-embed type="video" aspect="16by9" controls :poster="currentVideo.poster">
        <source :src="currentVideo.video" :type="currentVideo.video_type">
      </b-embed>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import appconfig from "../app/config";

export default {
  name: "help",
  data() {
    return {
      videos: [],
      tabIndex: 0,
      currentVideo: {
        'title' : '',
        'poster' : '',
        'video' : '',
        'video_type' : '',
      },
    }
  },
  methods: {
    showVideo(video) {
      this.currentVideo = video;
      this.$bvModal.show('modalVideo')
    }
  },
  mounted() {
    axios.get(
        appconfig.Point + 'api/constructor/v1/video'
    ).then(response => {
      this.videos = response.data.data;
      this.tabIndex = 0;
    }).catch(r => {

    })
  }
}
</script>

<style scoped>
@import '../assets/help.css';
</style>