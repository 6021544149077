<template>
  <div>
    <div class="screed_table_wrapper">
      <table class="screed_table table table-bordered" width="10%">
        <thead>
        <tr>
          <th colspan="2">
            <div class="table__header">
              №
              <button class="button button__medium" @click="addScreed">Додати</button>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(screed, index) in screeds" :key="index">
          <td width="90%">{{ screed.No }}</td>
          <td width="10%">
            <div class="table__buttons">
              <button class="button button__icon" @click="removeScreeds(index)">
                <img :src="require('@/assets/img/trash-can.png')">
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {toInteger} from "lodash";

export default {
  name: "screeds",
  computed: {
    screeds() {
      return this.$store.state.data.screeds;
    }
  },
  methods: {
    addScreed() {
      this.$store.commit('data/ADD_SCREED', {
        No: this.screeds.length > 0 ? toInteger(this.screeds[this.screeds.length - 1].No) + 1 : 2
      })
    },
    removeScreeds(i) {
      this.$store.commit('data/REMOVE_SCREED', i)
    }
  }
}
</script>
<style>
.hidend {
  display: none;
}
</style>