<template>
  <div class="accordion_row">
    <div class="order-confirm">
      <div class="order-confirm__buttons">

        <button :disabled="(this.details.length < 1) || loading" @click="toWork" class="button">
          Замовити
        </button>
        <button :disabled="(this.details.length < 1) || loading" @click="calcOrder" class="button">
          Розрахунок
        </button>
        <button @click="saveDraft" :disabled="(this.details.length === 0) || loading" class="button">
          Зберегти
        </button>
      </div>
      <div class="order-confirm__buttons">
        <button :disabled="(this.details.length < 1) || loading" class="button button__grey"
                @click="drawCut">
          Карти крою
        </button>
        <button class="button button__grey" :disabled="(this.details.length < 1) || loading"
                @click="checkDetails">
          Перевірити деталювання
        </button>
        <button class="button button__grey" :disabled="(this.details.length === 0) || loading"
                @click="clearB">
          Очистити форму
        </button>

      </div>
    </div>
    <file-modal :pdfExists="pdfFile.exists" :pdfFile="pdfFile.path"/>

  </div>
</template>

<script>
import axios from 'axios';
import appconfig from "../app/config";
import moment from 'moment';
import FileModal from "@/components/modals/pdfModal";

export default {
  name: "order",
  components: {
    moment, FileModal
  },
  data() {
    return {
      loading: false,
      check_parts: false,
      pdfFile: {
        path: "",
        exists: false,
      },
      order: {
        "readonly": false,
        "dept": '',
        "section": '',
        "customer": '',
        "stock": '',
        "cy": '',
        "manager": '',
        "price_type": '',
        "comment": "",
        "date_sale": moment().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'),
        "glue": false,
        "edge_size": false,
        "customer_edge": false,
        "customer_material": false,
        "to_work": false,
        "draw_cut": false,
        "check_parts": false,
        "materials_list": [],
        "edge_list": [],
        "screed_list": [],
        "name_list": [],
        "items": [],
      },
      resp: app.response,
      sum: 0,
      client: this.$store.state.auth.user
    }
  },
  computed: {
    details: {
      get() {
        return this.$store.state.data.details;
      },
      set(val) {
        return this.$store.commit('data/SET_DETAILS', val);
      },
    },
    detail() {
      return this.$store.state.data.detail;
    }
  },
  watch: {
    '$route': 'fetchData',
    loading(val) {
      if (val) {
        document.querySelector('body').classList.add('loading');
      } else {
        document.querySelector('body').classList.remove('loading');
      }
    }
  },
  methods: {
    processDetailsNo() {
      this.details = this.details.map((detail, index) => {
        detail.No = index + 1;
        return detail;
      })
    },
    saveDraft() {

      if(document.getElementById('detailTableForm').reportValidity() === false) {
        return false;
      }
      this.loading = true;
      this.$toast.info("Зберігаємо...");
      this.order.draft = 1;
      this.saveOrder();
    },
    calcOrder() {
      if(document.getElementById('detailTableForm').reportValidity() === false) {
        return false;
      }
      this.$toast.info("Вже рахуємо")
      this.loading = true;
      axios.post(
          appconfig.Point + 'api/constructor/v1/order/calculate',
          this.getOrderObject()
      ).then(response => {
        response = response.data;
        this.$store.commit('data/SET_RESPONSE_DEBUG', response)
        this.$store.commit('data/SET_RESPONSE', response);
        if (response.Error == true) {
          if (typeof response.ErrorMsg != 'undefined') {
            this.$toast.error(response.ErrorMsg)
          } else {
            this.$toast.error("У деталіровці знайдені помилки! Зверніть увагу на червоні виділення полів, при наведенні на які буде показано правильний варіант.")
          }
        } else {
          document.querySelector('#orderModalBtn').click();
        }
        this.loading = false;
      }).catch(r => {
        this.$toast.error("Щось пішло не так")
        this.loading = false;
      })
    },
    async toWork() {
      if(document.getElementById('detailTableForm').reportValidity() === false) {
        return false;
      }
      this.loading = true;
      let orderId = this.order.id;
      this.$toast.info("Працюємо з замовленням")
      if (!this.order.id) {
        let url = 'api/constructor/v1/order/create';
        let res = await axios.postForm(
            appconfig.Point + url,
            this.getOrderObject()
        );
        orderId = res.data.order.id;
      } else {
        await axios.postForm(
            appconfig.Point + 'api/constructor/v1/order/update/' + this.order.id,
            this.getOrderObject()
        );
      }
      this.$toast.info("Запит у систему обліку")
      let response = await axios.get(
          appconfig.Point + 'api/constructor/v1/order/work/' + orderId,
          this.getOrderObject()
      );
      response = response.data;
      this.order = response.order;
      this.$store.commit('data/SET_RESPONSE_DEBUG', response)
      this.$store.commit('data/SET_RESPONSE', response['1c']);
      this.$store.commit('data/SET_ORDER', this.order);
      if (response['1c'].Error == true) {
        if (typeof response['1c'].ErrorMsg != 'undefined') {
          this.$toast.error(response['1c'].ErrorMsg)
        } else {
          this.$toast.error("У деталіровці знайдені помилки! Зверніть увагу на червоні виділення полів.")
        }
      } else {
        this.$toast.success("Замовлення створено")
        document.querySelector('#orderModalBtn').click();
        this.clear();
      }
      this.loading = false;
    },
    drawCut() {
      if(document.getElementById('detailTableForm').reportValidity() === false) {
        return false;
      }
      this.loading = true;
      this.order.draw_cut = true;
      this.getDrawCut();
    },
    checkDetails() {
      if(document.getElementById('detailTableForm').reportValidity() === false) {
        return false;
      }
      this.loading = true;
      this.order.check_parts = true;
      this.validateOrder();
    },
    getDrawCut() {

      this.$toast.info('Малюємо карту крою')
      axios.post(
          appconfig.Point + 'api/constructor/v1/order/draw-cut',
          this.getOrderObject()
      ).then(response => {
        response = response.data;
        if (response.Error == true) {
          this.$store.commit('data/SET_RESPONSE_DEBUG', response)
          this.$store.commit('data/SET_RESPONSE', response);
          if (typeof response.ErrorMsg != 'undefined') {
            this.$toast.error(response.ErrorMsg)
          } else {
            this.$toast.error("У деталіровці знайдені помилки! Зверніть увагу на червоні виділення полів, при наведенні на які буде показано правильний варіант.")
          }
        } else {
          this.pdfFile.path = response.pdf;
          this.pdfFile.exists = true;
          this.$bvModal.show('fileModal')
        }
        this.loading = false;
      }).then(r => {
        this.loading = false;
      })
    },
    validateOrder() {
      this.$toast.info("Запит у систему обліку")
      axios.post(
          appconfig.Point + 'api/constructor/v1/order/validate',
          this.getOrderObject()
      ).then(response => {
        response = response.data;
        this.$store.commit('data/SET_RESPONSE_DEBUG', response)
        this.$store.commit('data/SET_RESPONSE', response);
        if (response.Error == true) {
          if (typeof response.ErrorMsg != 'undefined') {
            this.$toast.error(response.ErrorMsg)
          } else {
            this.$toast.error("У деталіровці знайдені помилки! Зверніть увагу на червоні виділення полів, при наведенні на які буде показано правильний варіант.")
          }
        } else {
          this.$toast.success('Помилок не знайдено!')
        }
      }).then(r => {
        this.loading = false;
      })
    },
    saveOrder() {

      let url = 'api/constructor/v1/order/create';
      if (this.order.id != null) {
        url = 'api/constructor/v1/order/update/' + this.order.id;
      }
      axios.postForm(
          appconfig.Point + url,
          this.getOrderObject()
      ).then(response => {
        this.$store.commit('data/SET_RESPONSE_DEBUG', response.data)
        this.$toast.success("Успішно збережено");
        if(this.order.draft == 1) {
          this.order = response.data.order;
        }
      }).then(r => {
        this.loading = false;
      }).catch(e => {
        this.loading = false;
        this.$toast.error("Щось пішло не так =(");
      })
    },
    clear() {
      this.$store.commit('data/SET_DETAILS', []);
      this.$store.commit('data/SET_DETAIL', {});
    },
    clearB() {
      if (!confirm('Ви впевнені?')) {
        return;
      }
      this.order = {
        "readonly": false,
        "dept": '',
        "doc_no": '',
        "section": '',
        "customer": '',
        "stock": '',
        "cy": '',
        "manager": '',
        "price_type": '',
        "comment": "",
        "date_sale": moment().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'),
        "glue": false,
        "edge_size": false,
        "customer_edge": false,
        "customer_material": false,
        "to_work": false,
        "draw_cut": false,
        "check_parts": false,
        "materials_list": [],
        "edge_list": [],
        "screed_list": [],
        "name_list": [],
        "items": [],
      };
      this.$store.commit('data/SET_DETAILS', []);
      this.$store.commit('data/SET_MATERIALS', []);
      this.$store.commit('data/SET_EDGES', []);
      this.$store.commit('data/SET_SCREEDS', []);
      this.$store.commit('data/SET_NAMES', []);
      this.$store.commit('data/SET_DETAIL', {});
      this.$store.commit('data/SET_ORDER', this.order);

    },
    getOrderObject() {
      this.$store.commit('data/SET_RESPONSE', null);
      this.processDetailsNo();

      const { user } = this.$store.state.auth;
      const { materials, edges, screeds, detailsNames } = this.$store.state.data;
      const production_params = user.client.production_params[0];

      const cli = {
        dept: "ТПМ",
        section: production_params.section,
        customer: user.client.ref,
        stock: user.defaultStock,
        cy: "980",
        manager: production_params.manager,
        price_type: production_params.price_type,
        draw_cut: this.order.draw_cut,
        to_work: this.order.to_work,
        check_parts: this.order.check_parts,
        comment: this.order.comment,
        materials_list: materials.length ? materials : [],
        edge_list: edges.length ? edges : [],
        screed_list: screeds.length ? screeds : [],
        name_list: detailsNames.length ? detailsNames : [],
      };

      this.order = {
        ...this.order,
        items: this.details,
        check_parts: this.check_parts,
      };

      return {
        ...this.order,
        ...cli,
        materials_list_color: materials.map(item => item.color),
        edge_list_color: edges.map(item => item.color),
        materials_list: materials.map(item => item.ref),
        edge_list: edges.map(item => item.ref),
      };
    },
    loadOrder(id) {
      axios.get(
          appconfig.Point + 'api/constructor/v1/order/view/' + id
      )
          .then(response => {
            this.loading = false;
            this.order = {
              ...response.data.data,
              ...{
                doc : '',
                doc_no : '',
                date_ready : '',
                id : response.data.data.doc_no == null ? response.data.data.id : null,
                date_sale : moment().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'),
                "to_work": false,
                "draw_cut": false,
                "check_parts": false,
              }
            };

            this.$store.commit('data/RESET_STATE');
            this.$store.commit('data/SET_ORDER', this.order);
            this.$store.commit('data/SET_DETAILS', response.data.data.items);
            this.$store.commit('data/SET_MATERIALS', response.data.data.materials_list);
            this.$store.commit('data/SET_EDGES', response.data.data.edge_list);
            this.$store.commit('data/SET_SCREEDS', response.data.data.screed_list || []);
            this.$store.commit('data/SET_NAMES', response.data.data.name_list || []);
            this.$store.commit('data/SET_DETAIL', response.data.data.items[0]);

          })
          .then(r => {
            this.loading = false;
          })
    },
    fetchData() {
      this.$store.commit('data/SET_RESPONSE', null)
      if (this.$route.params.id) {
        this.loading = true;
        this.loadOrder(this.$route.params.id);
      } else {
        this.order = {
          "id": null,
          "dept": '',
          "doc_no": '',
          "section": '',
          "customer": '',
          "stock": '',
          "cy": '',
          "manager": '',
          "price_type": '',
          "comment": "",
          "date_sale": moment().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'),
          "glue": false,
          "edge_size": false,
          "customer_edge": false,
          "customer_material": false,
          "to_work": false,
          "draw_cut": false,
          "check_parts": false,
          "materials_list": [],
          "edge_list": [],
          "screed_list": [],
          "name_list": [],
          "items": [],
        };
        this.$store.commit('data/SET_DETAILS', []);
        this.$store.commit('data/SET_MATERIALS', []);
        this.$store.commit('data/SET_EDGES', []);
        this.$store.commit('data/SET_SCREEDS', []);
        this.$store.commit('data/SET_NAMES', []);
        this.$store.commit('data/SET_DETAIL', {});
        this.$store.commit('data/SET_ORDER', this.order);
      }
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
    this.$root.$on('update', i => {
      this.$forceUpdate();
    });

  },
}

</script>

<style scoped>
.custom-control-label {
  color: black;
}
</style>