<template>
  <div class="main-panel">
    <div>
      <h2>Список замовлень</h2>

      <b-row class="mb-2 mt-5">

        <b-col md="2">

          <b-input-group size="sm">
            <b-form-datepicker
                v-model="filter.date"
                today-button
                reset-button
                close-button
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="ru"
                label-close-button="Закрити"
                label-reset-button="Скинути"
                label-today-button="Сьогодні"
                placeholder="Дата Створення"
            ></b-form-datepicker>
          </b-input-group>
        </b-col>

        <b-col md="2">

          <b-input-group size="sm">
            <b-form-datepicker
                v-model="filter.date_ready"
                today-button
                reset-button
                close-button
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="ru"
                label-close-button="Закрити"
                label-reset-button="Скинути"
                label-today-button="Сьогодні"
                placeholder="Дата Готовності"
            ></b-form-datepicker>
          </b-input-group>
        </b-col>

        <b-col md="3">
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model.lazy="filter.query"
                type="search"
                placeholder="Пошук"
            ></b-form-input>
          </b-input-group>
        </b-col>

        <b-col md="2">

          <b-input-group size="sm">
            <b-form-select
                v-model.number="filter.draft"
                type="search"
            >
              <option value="">Чернетка</option>
              <option value="1">Так</option>
              <option value="0">Ні</option>
            </b-form-select>
          </b-input-group>
        </b-col>
        <b-col md="2" class="button-list">
          <button class="btn btn-outline-success btn-sm" @click="search">Шукати</button>
          <button class="btn btn-outline-danger btn-sm" @click="reset">Скинути</button>
        </b-col>

      </b-row>


      <div class="table-responsive">
        <table class="help-table table table-bordered table-striped">
          <thead>
          <tr>
            <th>№</th>
            <th>Дата створення</th>
            <th>Список виробів</th>
            <th>Чернетка</th>
            <th>Коментар</th>
            <th>Номер замовлення</th>
            <th>Сума замовлення</th>
            <th>Дата готовності</th>
            <th style="width:150px"></th>
          </tr>
          </thead>
          <tbody v-if="loading" role="rowgroup">
          <tr role="row" class="b-table-busy-slot">
            <td colspan="9" role="cell" class="">
              <div class="text-center text-danger my-2"><span aria-hidden="true" class="align-middle spinner-border"><!----></span>
              </div>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr v-for="(order, index) in orders" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ order.created_at }}</td>
            <td>{{
                order.items.filter(item => item.name !== '' && item.name != null).map(item => {
                  return item.name
                }).filter((v, i, self) => i == self.indexOf(v)).join(', ')
              }}
            </td>
            <td>{{ order.draft == 1 ? "Так" : "Ні" }}</td>
            <td>{{ order.comment }}</td>
            <td>{{ order.doc_no }}</td>
            <td>{{ sum(order) }}</td>
            <td>{{ order.date_ready }}</td>
            <td>
              <router-link :to="{name:'order-edit', params : {id : order.id}}" v-if="!order.doc_no" class="btn btn-outline-primary mr-2 btn-sm">
                <i class="fa fa-pen"></i>
              </router-link>

              <router-link :to="{name:'order-edit', params : {id : order.id}}" v-if="order.doc_no" class="btn btn-outline-primary mr-2 btn-sm">
                <i class="fa fa-copy"></i>
              </router-link>

              <button v-if="!order.doc_no" @click="deleteOrder(order)" class="btn btn-outline-danger btn-sm">
                <i class="fa fa-trash"></i>
              </button>

            </td>
          </tr>

          </tbody>
        </table>
      </div>
      <b-row>
        <b-col>
          <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import appconfig from "../../app/config";
import {orderService} from "../../services/orderService";

export default {
  data() {
    return {
      filter: {
        draft: '',
        date_ready: '',
        date: '',
        query: '',
      },
      loading: false,
      currentPage: 1,
      rows: 0,
      perPage: 0,
      orders: [],
      timer: null,
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    '$route': 'fetchData',
    currentPage(newVal, oldVal) {
      this.fetchData('&' + new URLSearchParams(this.filter).toString());
    },
  },
  methods: {
    reset() {
      this.filter = {
        draft: '',
        date_ready: '',
        date: '',
        query: '',
      }
      this.fetchData('&' + new URLSearchParams(this.filter).toString());
    },
    search() {
      this.currentPage = 1;
      this.fetchData('&' + new URLSearchParams(this.filter).toString());
    },
    sum(order) {
      let sum = 0;
      if (order.order_items != undefined) {
        order.order_items.forEach((p) => {
          sum += p.sum;
        })
      }
      return Number(sum).toFixed(2);
    },
    deleteOrder(order) {
      if (confirm('Ви впевнені?')) {
        this.loading = true;
        axios.get(
            appconfig.Point + 'api/constructor/v1/order/delete/' + order.id
        ).then(response => {
          this.loading = false;
          this.$toast.success(response.data.message);
          this.fetchData();
        })
            .catch(r => {
              this.$toast.error('Щось пішло не так =(')
              this.loading = false;
            })
      }
    },
    fetchData(params = '') {
      this.loading = true;
      params = '?page=' + this.currentPage + params;
      orderService.getList(params)
          .then(response => {
            this.loading = false;
            this.orders = response.data;
            this.perPage = response.meta.per_page;
            this.rows = response.meta.total;
          })
    }
  },
  name: "order-list"
}
</script>

<style scoped>
.button-list {
  display: flex;
  gap: 5px;
}
</style>